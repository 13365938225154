import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnmountClosed } from 'react-collapse';
import { Sport } from '../../../types';
import { useGetNhlTeamsQuery, useGetNhlPlayersQuery } from 'src/redux/queries/nhlApi';
import { PlayerMarkets } from '../utils/processSportsMarkets';
import MarketTeams from '../MarketTeams';
import NHLPlayerHeader from '../Players/NHLPlayerHeader';
import NHLPlayerMarket from './NHLPlayerMarket';
import { useGetSportsMarketsQuery } from '../../../redux/queries/bettingApi';
import { Button, Flex, Spin } from 'antd';
import NoOfGamesDumb from '../../PlayerTrends/NoOfGames_Dumb';
import { normalizeName, findPlayerByName } from '../utils/normalizeText';
import { getTeam } from '../utils/getTeam';
import { PicksRecord } from '../OurPicks/OurPicks';

import './MLBPlayerMarket.scss';

const PAGE_COUNT = 10;
const LOAD_COUNT = 10;

// const getTeam = (teams: NhlTeam[] | undefined, teamName: string): NhlTeam | null => {
//   return (
//     (teams || []).find((team: NhlTeam) => {
//       console.log(`${team.teamCity} ${team.teamName}`, teamName);
//       return (
//         normalizeName(`${team.teamCity} ${team.teamName}`.toLocaleLowerCase().replace('.', '')) ===
//         normalizeName(teamName.toLocaleLowerCase().replace('.', ''))
//       );
//     }) || null
//   );
// };

export default function NHLMarket({
  homeTeam,
  awayTeam,
  startTime,
  playerMarkets,
  picks,
}: {
  homeTeam: string;
  awayTeam: string;
  startTime: string;
  playerMarkets: PlayerMarkets;
  picks: PicksRecord[];
}) {
  const { sportId } = useParams();
  const { data: players, isFetching: isFetchingPlayers } = useGetNhlPlayersQuery();
  const { data: markets } = useGetSportsMarketsQuery({ sport: sportId as Sport });
  const { data: teams } = useGetNhlTeamsQuery();
  const [pager, setPager] = useState<number>(PAGE_COUNT);
  const [noOfGames, setNoOfGames] = useState<number>(15);
  const handlePager = () => {
    if (pager < Object.keys(playerMarkets).length) {
      setPager(pager + LOAD_COUNT);
    }
  };

  const [isExpanded, setExpanded] = useState<Record<string, boolean>>({});

  const home_team = getTeam(teams, homeTeam);
  const away_team = getTeam(teams, awayTeam);

  useEffect(() => {
    if (Object.keys(playerMarkets ?? {}).length === 0) return;

    setTimeout(() => {
      setExpanded({
        [Object.keys(playerMarkets)[0]]: true,
      });
    }, 1000);
  }, [playerMarkets]);

  if (isFetchingPlayers)
    return (
      <Flex align="center" justify="center" style={{ height: 200 }}>
        <Spin size="large" />
      </Flex>
    );

  return (
    <div>
      <MarketTeams homeTeam={home_team} awayTeam={away_team} startTime={startTime} />
      <div style={{ paddingBottom: 16 }}>
        {/* <NoOfGamesDumb noOfGames={noOfGames} handleSetNoOfGames={setNoOfGames} /> */}
        {Object.keys(playerMarkets)
          .slice(0, pager)
          .map(playerName => {
            const player = findPlayerByName(playerName, players, home_team?.teamAbv, away_team?.teamAbv);
            if (!player) return null;

            const playerPicks = picks.filter(p => p.playerId === player.playerID);

            return (
              <div key={playerName} className="mlb-player-market__container">
                <NHLPlayerHeader
                  player={player}
                  handleExpandSection={e => {
                    e.stopPropagation();
                    setExpanded(prevExpanded => {
                      prevExpanded[playerName] = !prevExpanded[playerName];
                      return { ...prevExpanded };
                    });
                  }}
                  isExpanded={isExpanded[playerName]}
                  hasPicks={playerPicks.length > 0}
                  gameDate={startTime}
                />
                <UnmountClosed isOpened={isExpanded[playerName]}>
                  {Object.keys(playerMarkets[normalizeName(playerName)]).map(marketName => {
                    const market = markets?.find(m => m.key === marketName);

                    const marketPicks = playerPicks.filter(p => p.marketName === marketName);

                    return (
                      <NHLPlayerMarket
                        key={marketName}
                        player={player}
                        bettingMarkets={playerMarkets[normalizeName(playerName)][marketName]}
                        market={market}
                        opponent={
                          player.team === home_team?.teamAbv
                            ? (away_team?.teamAbv as string)
                            : (home_team?.teamAbv as string)
                        }
                        isHome={player.team === home_team?.teamAbv}
                        noOfGames={noOfGames}
                        hasPicks={marketPicks.length > 0}
                      />
                    );
                  })}
                </UnmountClosed>
              </div>
            );
          })}
        {Object.keys(playerMarkets).length &&
        Object.keys(playerMarkets).length > PAGE_COUNT &&
        pager < Object.keys(playerMarkets).length ? (
          <Button shape="round" type="primary" onClick={handlePager}>
            Load More
          </Button>
        ) : Object.keys(playerMarkets).length === 0 ? (
          <div>No lines at this time.</div>
        ) : null}
      </div>
    </div>
  );
}
