import React from 'react';
import { Skeleton, Avatar, Flex, Typography } from 'antd';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import './PlayerInfo.scss';

const { Title } = Typography;

type PlayerInfoProps = {
  headShot: string;
  teamLogo: string;
  displayName: string;
  isLoading: boolean;
  children?: React.ReactNode;
  playerAvgs?: { header: string; longHeader: string; stat: number | string }[];
};
const PlayerInfo = ({ isLoading, headShot, teamLogo, displayName, children, playerAvgs = [] }: PlayerInfoProps) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const containerCss = cn('player-info__container', {
    'player-info__container--mobile': isTabletOrMobile,
  });

  const avgCss = cn('player-info__avg', {
    'player-info__avg--mobile': isTabletOrMobile,
  });

  if (isLoading) {
    return (
      <div className={containerCss}>
        <div>
          <Skeleton.Avatar active={true} size={isTabletOrMobile ? 75 : 100} shape={'circle'} />
        </div>
        <Flex align="center" gap={8} vertical={isTabletOrMobile}>
          <div>
            <Skeleton.Avatar active={true} size={isTabletOrMobile ? 30 : 50} shape={'circle'} />
          </div>
          <div>
            <Skeleton.Input active={true} block={true} />
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <div className={containerCss}>
      <div>
        <Avatar style={{ backgroundColor: '#333' }} src={headShot} size={isTabletOrMobile ? 75 : 100} />
      </div>
      <Flex
        align={isTabletOrMobile ? 'start' : 'center'}
        gap={8}
        vertical={true}
        style={{ width: isTabletOrMobile ? 'auto' : '100%' }}
      >
        <Flex align="center" gap={8}>
          <div>
            <Avatar style={{ backgroundColor: '#333' }} src={teamLogo} size={isTabletOrMobile ? 30 : 50} />
          </div>
          <div>
            <Title level={5} style={{ margin: 0 }}>
              {displayName}
            </Title>
          </div>
        </Flex>
        {playerAvgs.length > 0 && (
          <Flex
            align={isTabletOrMobile ? 'start' : 'center'}
            gap={2}
            vertical={!isTabletOrMobile}
            style={{ width: isTabletOrMobile ? 'auto' : '75%' }}
          >
            {playerAvgs.map(avg => (
              <div className={avgCss}>
                <div className="player-info__avg-header">{isTabletOrMobile ? avg.header : avg.longHeader}</div>
                <div className="player-info__avg-stat">{avg.stat}</div>
              </div>
            ))}
          </Flex>
        )}
        {children && <>{children}</>}
      </Flex>
    </div>
  );
};

export default PlayerInfo;
