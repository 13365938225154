import React from 'react';
import { Flex, Button, Tag } from 'antd';
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { NflTeam, OddsEvent } from 'src/types';
import { useGetNflTeamsQuery, useGetNflGameOddsQuery } from 'src/redux/queries/nflApi';
import { PicksRecord } from '../OurPicks/OurPicks';
import { getTeam } from '../utils/getTeam';

import './BettingEvent.scss';

function NFLEvent({
  event,
  handleNavigate,
  picks,
}: {
  event: OddsEvent;
  handleNavigate: (eventId: string) => void;
  picks: PicksRecord[];
}) {
  const { data: teams } = useGetNflTeamsQuery();

  const { data: gameOdds } = useGetNflGameOddsQuery(
    { gameDate: dayjs(event.commence_time).format('YYYYMMDD') },
    { skip: !event.commence_time }
  );

  const homeTeam = getTeam(teams, event.home_team);
  const awayTeam = getTeam(teams, event.away_team);

  const gameKey =
    gameOdds && gameOdds.body
      ? Object.keys(gameOdds.body).find(key => key.includes(`${awayTeam?.teamAbv}@${homeTeam?.teamAbv}`)) || null
      : null;

  const impliedTotals = gameKey ? gameOdds.body[gameKey].draftkings : null;

  const Team2 = ({
    team,
    isHome,
    minus,
    total,
  }: {
    team: NflTeam;
    isHome: boolean;
    minus: string | null;
    total: string | null;
  }) => {
    const logoCss = cn('betting__event-logo-avatar', {
      'betting__event-logo-avatar--home': isHome,
    });

    return (
      <div>
        <Flex align="flex-start">
          <div className="betting__event-logo">
            <div className={logoCss}>
              <div>
                <img src={team?.espnLogo1} alt={team?.teamAbv} />
              </div>
              {minus && <div className="betting__event-plusminus">{minus}</div>}
              {total && <div className="betting__event-team-total">{total}</div>}
            </div>
            <div className="betting__event-team-wrapper">
              <div className="betting__event-team-name">
                {team?.teamName} <span className="city">{team?.teamCity}</span>
              </div>
              <div className="betting__event-win-loss">
                {team?.wins} - {team?.loss}
              </div>
            </div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <div className="betting__event-wrapper">
      {picks.length > 0 && (
        <div className="betting__event-picks">
          <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}></Tag>
        </div>
      )}
      <div className="betting__event betting__event--secondary">
        <Flex align="center" justify="space-between">
          <Flex align="flex-start" vertical={true} justify="space-between">
            <Team2
              team={homeTeam as NflTeam}
              isHome
              minus={
                impliedTotals && +impliedTotals?.impliedTotals.homeTotal < +impliedTotals?.impliedTotals.awayTotal
                  ? impliedTotals?.totalOver || impliedTotals?.totalUnder
                  : null
              }
              total={
                impliedTotals && +impliedTotals?.impliedTotals.homeTotal > +impliedTotals?.impliedTotals.awayTotal
                  ? impliedTotals?.homeTeamSpread
                  : null
              }
            />
            <Team2
              team={awayTeam as NflTeam}
              isHome={false}
              minus={
                impliedTotals && +impliedTotals?.impliedTotals.homeTotal > +impliedTotals?.impliedTotals.awayTotal
                  ? impliedTotals?.totalOver || impliedTotals?.totalUnder
                  : null
              }
              total={
                impliedTotals && +impliedTotals?.impliedTotals.homeTotal < +impliedTotals?.impliedTotals.awayTotal
                  ? impliedTotals?.awayTeamSpread
                  : null
              }
            />
          </Flex>
          <Flex gap={8} vertical={true} align="center" justify="space-between">
            <Flex className="betting__event-start-time" gap={8}>
              <div>{dayjs(event.commence_time).format('h:mm A')}</div>
            </Flex>
            <Flex gap={8} align="center">
              <Button
                onClick={() => handleNavigate(event.id)}
                icon={<ArrowRightOutlined />}
                shape="round"
                type="primary"
                iconPosition="end"
              >
                Props
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default NFLEvent;
