export function priceAdjustedRating({ rating, price }: { rating: number; price: number }): number {
  return price >= 150
    ? rating * 1.002
    : price >= 110
      ? rating * 1.001
      : price <= -1000
        ? rating * 0.4
        : price <= -500
          ? rating * 0.6
          : price <= -200
            ? rating * 0.95
            : rating;
}
