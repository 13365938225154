import React from 'react';
import dayjs from 'dayjs';
import {
  MlbPlayerInfo,
  NbaPlayerInfo,
  NflPlayerInfo,
  NhlPlayerInfo,
  Sport,
  MlbTeam,
  NbaTeam,
  NflTeam,
  NhlTeam,
} from '../../types';
import PlayerHeader from '../Betting/PlayerHeader';
import { useGetNflTeamsQuery } from '../../redux/queries/nflApi';
import { useGetMlbTeamsQuery } from 'src/redux/queries/mlbApi';
import { useGetNbaTeamsQuery } from 'src/redux/queries/nbaApi';
import { useGetNhlTeamsQuery } from 'src/redux/queries/nhlApi';
import { PicksRecord } from '../Betting/OurPicks/OurPicks';

function FavoritePlayerHeader({
  sport,
  player,
  pick,
}: {
  sport: Sport;
  player: MlbPlayerInfo | NbaPlayerInfo | NflPlayerInfo | NhlPlayerInfo | undefined;
  pick: PicksRecord | undefined;
}) {
  const { data: nflTeams } = useGetNflTeamsQuery(undefined, { skip: sport !== Sport.nfl });
  const { data: mlbTeams } = useGetMlbTeamsQuery(undefined, { skip: sport !== Sport.mlb });
  const { data: nbaTeams } = useGetNbaTeamsQuery(undefined, { skip: sport !== Sport.nba });
  const { data: nhlTeams } = useGetNhlTeamsQuery(undefined, { skip: sport !== Sport.nhl });

  const teams: (MlbTeam | NbaTeam | NflTeam | NhlTeam)[] | undefined =
    sport === Sport.nfl
      ? nflTeams
      : sport === Sport.mlb
        ? mlbTeams
        : sport === Sport.nba
          ? nbaTeams
          : sport === Sport.nhl
            ? nhlTeams
            : [];

  const team = teams?.find((t: MlbTeam | NbaTeam | NflTeam | NhlTeam) => t.teamAbv === player?.body.team) || null;

  // @ts-ignore
  const playerHeadshot = sport === Sport.mlb ? player?.body.mlbHeadshot : player?.body.espnHeadshot;

  const TeamName = ({ teamAbv }: { teamAbv: string }) => {
    if (player?.body.team === teamAbv) {
      return <strong>{teamAbv}</strong>;
    }

    return <>{teamAbv}</>;
  };

  return (
    <PlayerHeader
      sport={sport as Sport}
      loading={false}
      teamId={team?.teamAbv as string}
      playerId={player?.body.playerID as string}
      playerName={player?.body.longName as string}
      headshot={playerHeadshot as string}
      teamName={team?.teamName as string}
      teamLogo={team?.espnLogo1 as string}
      teamCity={team?.teamCity as string}
      position={player?.body.pos as string}
      jerseyNum={player?.body.jerseyNum as string}
      showTeam={!Boolean(pick)}
      gameDetails={
        <div className="pick__game-details">
          <div>
            <TeamName teamAbv={pick?.homeTeam?.teamAbv as string} /> vs.{' '}
            <TeamName teamAbv={pick?.awayTeam?.teamAbv as string} />{' '}
            <span className="pick__game-time">at {dayjs(pick?.commence_time).format('MM/DD, hh:mm A')}</span>
          </div>
        </div>
      }
      gameDate={pick?.commence_time}
    />
  );
}

export default FavoritePlayerHeader;
