import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Select, Segmented } from 'antd';
import { MIN_NO_OF_GAMES, MAX_NO_OF_GAMES, MOBILE_QUERY } from '../../constants';
import { useGetUserInfo } from '../../hooks/useGetUser';
import { useMediaQuery } from 'react-responsive';

export default function NoOfGames_Dumb({
  noOfGames,
  handleSetNoOfGames,
}: {
  noOfGames: number;
  handleSetNoOfGames: (noOfGames: number) => void;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const {
    user: { basicPlan, proPlan },
  } = useGetUserInfo();

  return (
    <Flex justify="center" gap={8} align="center" style={{ padding: '8px 0' }} vertical={true}>
      <Segmented
        size={isTabletOrMobile ? 'small' : 'large'}
        value={noOfGames}
        onChange={handleSetNoOfGames}
        options={[
          { label: 'Last 3', value: 3 },
          ...(basicPlan || proPlan
            ? [
                { label: 'Last 5', value: 5 },
                { label: 'Last 10', value: 10 },
                { label: 'Last 15', value: 15 },
                { label: 'Last 20', value: 20 },
              ]
            : []),
        ]}
      />
      {/* <Select
        variant="filled"
        defaultValue={noOfGames}
        value={noOfGames}
        style={{ width: 175 }}
        onChange={handleSetNoOfGames}
        options={Array(basicPlan || proPlan ? MAX_NO_OF_GAMES : MIN_NO_OF_GAMES)
          .fill({})
          .map((_, index) => {
            return {
              value: index + 1,
              label: `Last ${index + 1} games`,
            };
          })}
      /> */}
      {!(basicPlan || proPlan) && (
        <p style={{ marginTop: 8 }}>
          Want more history? <Link to="/pricing">Cick here.</Link>
        </p>
      )}
    </Flex>
  );
}
