import React from 'react';
import { Sport, NhlStatsMetric } from '../../types';
import type { TableProps } from 'antd';

interface DataType {
  key: string;
  game: string;
  gameDate: number;
}

const nbaColumns: TableProps<DataType>['columns'] = [
  {
    title: 'Mins',
    dataIndex: 'mp',
    key: 'mp',
    width: 75,
    render: (_: string, record: any) => {
      return <>{record.mp ? record.mp.toFixed(1) : ''}</>;
    },
  },
  {
    title: 'Pts',
    dataIndex: 'pts',
    key: 'pts',
    width: 75,
  },
  {
    title: 'Fg',
    dataIndex: 'Fg',
    key: 'Fg',
    render: (_: string, record: any) => {
      return (
        <>
          {record.fg}-{record.fga}
        </>
      );
    },
    width: 75,
  },
  {
    title: 'Fgp',
    dataIndex: 'fg_pct',
    key: 'fg_pct',
    width: 75,
  },
  {
    title: '3Fg',
    dataIndex: '3Fg',
    key: '3Fg',
    render: (_: string, record: any) => {
      return (
        <>
          {record.fg3}-{record.fg3a}
        </>
      );
    },
    width: 75,
  },
  {
    title: 'Ast',
    dataIndex: 'ast',
    key: 'ast',
    width: 75,
  },
  {
    title: 'Reb',
    dataIndex: 'trb',
    key: 'trb',
    width: 75,
  },
  {
    title: 'Blk',
    dataIndex: 'blk',
    key: 'blk',
    width: 75,
  },
  {
    title: 'Stl',
    dataIndex: 'stl',
    key: 'stl',
    width: 75,
  },
  {
    title: 'To',
    dataIndex: 'tov',
    key: 'tov',
    width: 75,
  },
];

const nflColumns: Record<string, TableProps<DataType>['columns']> = {
  Passing: [
    {
      title: 'Passing',
      children: [
        {
          title: 'Rtg',
          dataIndex: 'pass_rating',
          key: 'pass_rating',
          width: 50,
        },
        {
          title: 'Att',
          dataIndex: 'pa',
          key: 'pa',
          width: 50,
          render: (_: string, record: any) => {
            return (
              <>
                {record['pass_cmp']}-{record['pass_att']}
              </>
            );
          },
        },
        {
          title: 'Yds',
          dataIndex: 'pass_yds',
          key: 'pass_yds',
          width: 50,
        },
        {
          title: 'Lng',
          dataIndex: 'pass_long',
          key: 'pass_long',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'pass_td',
          key: 'pass_td',
          width: 50,
        },
        {
          title: 'Ints',
          dataIndex: 'pass_int',
          key: 'pass_int',
          width: 50,
        },
      ],
    },
  ],
  Rushing: [
    {
      title: 'Rushing',
      children: [
        {
          title: 'Att',
          dataIndex: 'rush_att',
          key: 'rush_att',
          width: 50,
        },
        {
          title: 'Yds',
          dataIndex: 'rush_yds',
          key: 'rush_yds',
          width: 50,
        },
        {
          title: 'Lng',
          dataIndex: 'rush_long',
          key: 'rush_long',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'rush_td',
          key: 'rush_td',
          width: 50,
        },
      ],
    },
  ],
  Receiving: [
    {
      title: 'Receiving',
      children: [
        {
          title: 'Tgts',
          dataIndex: 'targets',
          key: 'targets',
          width: 50,
        },
        {
          title: 'Rec',
          dataIndex: 'rec',
          key: 'rec',
          width: 50,
        },
        {
          title: 'Yds',
          dataIndex: 'rec_yds',
          key: 'rec_yds',
          width: 50,
        },
        {
          title: 'Lng',
          dataIndex: 'rec_long',
          key: 'rec_long',
          width: 50,
        },
        {
          title: 'Avg',
          dataIndex: 'rec_yds_per_rec',
          key: 'rec_yds_per_rec',
          width: 50,
        },
        {
          title: 'Tds',
          dataIndex: 'rec_td',
          key: 'rec_td',
          width: 50,
        },
      ],
    },
  ],
  Kicking: [
    {
      title: 'Kicking',
      children: [
        {
          title: 'Fgm',
          dataIndex: 'Kicking.fgMade',
          key: 'Kicking.fgMade',
          width: 50,
          render: (_: string, record: any) => {
            return (
              <>
                {record['fgm']}-{record['fga']}
              </>
            );
          },
        },
        // {
        //   title: 'Long',
        //   dataIndex: 'Kicking.fgLong',
        //   key: 'Kicking.fgLong',
        //   width: 50,
        // },
        {
          title: 'Fg Pct',
          dataIndex: 'fg_perc',
          key: 'fg_perc',
          width: 50,
        },
        {
          title: 'xpMade',
          dataIndex: 'Kicking.xpMade',
          key: 'Kicking.xpMade',
          width: 50,
          render: (_: string, record: any) => {
            return (
              <>
                {record['xpm']}-{record['xpa']}
              </>
            );
          },
        },
        {
          title: 'Fg xp',
          dataIndex: 'xp_perc',
          key: 'xp_perc',
          width: 50,
        },
        {
          title: 'Pts',
          dataIndex: 'scoring',
          key: 'scoring',
          width: 50,
        },
      ],
    },
  ],
  Defense: [
    {
      title: 'Defense',
      children: [
        {
          title: 'Tot Tackles',
          dataIndex: 'tackles_combined',
          key: 'tackles_combined',
          width: 50,
        },
        {
          title: 'Solo Tackles',
          dataIndex: 'tackles_solo',
          key: 'tackles_solo',
          width: 50,
        },
        {
          title: 'Ints',
          dataIndex: 'def_int',
          key: 'def_int',
          width: 50,
        },
        {
          title: 'Sacks',
          dataIndex: 'sacks',
          key: 'sacks',
          width: 50,
        },
        {
          title: 'QB hits',
          dataIndex: 'qb_hits',
          key: 'qb_hits',
          width: 50,
        },
      ],
    },
  ],
};

const mlbColumns: Record<string, TableProps<DataType>['columns']> = {
  Pitching: [
    {
      title: 'Pitching',
      children: [
        {
          title: 'Count',
          dataIndex: 'Pitching.Pitches',
          key: 'Pitching.Pitches',
          width: 50,
        },
        {
          title: 'So',
          dataIndex: 'Pitching.SO',
          key: 'Pitching.SO',
          width: 50,
        },
        {
          title: 'Walks',
          dataIndex: 'Pitching.BB',
          key: 'Pitching.BB',
          width: 50,
        },
        {
          title: 'Balks',
          dataIndex: 'Pitching.Balk',
          key: 'Pitching.Balk',
          width: 50,
        },
        {
          title: 'H',
          dataIndex: 'Pitching.H',
          key: 'Pitching.H',
          width: 50,
        },
        {
          title: 'HR',
          dataIndex: 'Pitching.HR',
          key: 'Pitching.HR',
          width: 50,
        },
        {
          title: 'R',
          dataIndex: 'Pitching.R',
          key: 'Pitching.R',
          width: 50,
        },
      ],
    },
  ],
  Hitting: [
    {
      title: 'Hitting',
      children: [
        {
          title: 'HR',
          dataIndex: 'Hitting.HR',
          key: 'Hitting.HR',
          width: 50,
        },
        {
          title: 'H',
          dataIndex: 'Hitting.H',
          key: 'Hitting.H',
          width: 50,
        },
        {
          title: 'RBIs',
          dataIndex: 'Hitting.RBI',
          key: 'Hitting.RBI',
          width: 50,
        },
        {
          title: 'R',
          dataIndex: 'Hitting.R',
          key: 'Hitting.R',
          width: 50,
        },
        {
          title: 'TB',
          dataIndex: 'Hitting.TB',
          key: 'Hitting.TB',
          width: 50,
        },
        {
          title: 'SO',
          dataIndex: 'Hitting.SO',
          key: 'Hitting.SO',
          width: 50,
        },
        {
          title: 'W',
          dataIndex: 'Hitting.BB',
          key: 'Hitting.BB',
          width: 50,
        },
        {
          title: '2B',
          dataIndex: 'Hitting.2B',
          key: 'Hitting.2B',
          width: 50,
        },
        {
          title: '3B',
          dataIndex: 'Hitting.3B',
          key: 'Hitting.3B',
          width: 50,
        },
      ],
    },
  ],
  BaseRunning: [
    {
      title: 'Base Running',
      children: [
        {
          title: 'SB',
          dataIndex: 'BaseRunning.SB',
          key: 'BaseRunning.SB',
          width: 50,
        },
        {
          title: 'CS',
          dataIndex: 'BaseRunning.CS',
          key: 'BaseRunning.CS',
          width: 50,
        },
        {
          title: 'PO',
          dataIndex: 'BaseRunning.PO',
          key: 'BaseRunning.PO',
          width: 50,
        },
      ],
    },
  ],
};

const nhlColumns: Record<string, TableProps<DataType>['columns']> = {
  Goalie: [
    {
      title: 'Saves',
      dataIndex: NhlStatsMetric.GOALIE_SAVES,
      key: NhlStatsMetric.GOALIE_SAVES,
      width: 75,
    },
    // {
    //   title: 'PP Saves',
    //   dataIndex: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
    //   key: NhlStatsMetric.GOALIE_POWERPLAY_SAVES,
    //   width: 75,
    // },
    // {
    //   title: 'SH Saves',
    //   dataIndex: NhlStatsMetric.GOALIE_SHORT_HANDED_SAVES,
    //   key: NhlStatsMetric.GOALIE_SHORT_HANDED_SAVES,
    //   width: 75,
    // },
    {
      title: 'Goals',
      dataIndex: NhlStatsMetric.GOALIE_GOALS_AGAINST,
      key: NhlStatsMetric.GOALIE_GOALS_AGAINST,
      width: 75,
    },
    {
      title: 'Shots Agst',
      dataIndex: NhlStatsMetric.GOALIE_SHOTS_AGAINST,
      key: NhlStatsMetric.GOALIE_SHOTS_AGAINST,
      width: 75,
    },
  ],
  Metric: [
    {
      title: 'Ice Time',
      dataIndex: NhlStatsMetric.TIME_ON_ICE,
      key: NhlStatsMetric.TIME_ON_ICE,
      width: 75,
      render: (_: string, record: any) => {
        return <>{record[NhlStatsMetric.TIME_ON_ICE] ? record[NhlStatsMetric.TIME_ON_ICE].toFixed(1) : ''}</>;
      },
    },
    {
      title: 'Points',
      dataIndex: NhlStatsMetric.POINTS,
      key: NhlStatsMetric.POINTS,
      width: 75,
      render: (_: string, record: any) => {
        return <>{+record[NhlStatsMetric.GOALS] + +record[NhlStatsMetric.ASSISTS]}</>;
      },
    },
    {
      title: 'Goals',
      dataIndex: NhlStatsMetric.GOALS,
      key: NhlStatsMetric.GOALS,
      width: 75,
    },
    {
      title: 'Assists',
      dataIndex: NhlStatsMetric.ASSISTS,
      key: NhlStatsMetric.ASSISTS,
      width: 75,
    },
    {
      title: 'PP Points',
      dataIndex: 'powerplayPoints',
      key: 'powerplayPoints',
      width: 75,
      render: (_: string, record: any) => {
        return <>{+record[NhlStatsMetric.POWERPLAY_GOALS] + +record[NhlStatsMetric.POWERPLAY_ASSISTS]}</>;
      },
    },
    {
      title: 'PP Goals',
      dataIndex: NhlStatsMetric.POWERPLAY_GOALS,
      key: NhlStatsMetric.POWERPLAY_GOALS,
      width: 75,
    },
    {
      title: 'PP Assists',
      dataIndex: NhlStatsMetric.POWERPLAY_ASSISTS,
      key: NhlStatsMetric.POWERPLAY_ASSISTS,
      width: 75,
    },
    {
      title: 'Shots',
      dataIndex: NhlStatsMetric.SHOTS,
      key: NhlStatsMetric.SHOTS,
      width: 75,
    },
    {
      title: 'Blocked Shots',
      dataIndex: NhlStatsMetric.BLOCKED_SHOTS,
      key: NhlStatsMetric.BLOCKED_SHOTS,
      width: 75,
    },
    {
      title: 'Hits',
      dataIndex: NhlStatsMetric.HITS,
      key: NhlStatsMetric.HITS,
      width: 75,
    },
    // {
    //   title: 'Shots Missed Net',
    //   dataIndex: NhlStatsMetric.SHOTS_MISSED_NET,
    //   key: NhlStatsMetric.SHOTS_MISSED_NET,
    //   width: 75,
    // },
    {
      title: 'SH Goals',
      dataIndex: NhlStatsMetric.SHORT_HANDED_GOALS,
      key: NhlStatsMetric.SHORT_HANDED_GOALS,
      width: 75,
    },
  ],
};

export const tableStatColumns = (sport: Sport, position: string | undefined, isMobile: boolean) => {
  const commonColumns: TableProps<DataType>['columns'] = [
    {
      title: 'Game',
      dataIndex: 'game',
      key: 'game',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'gameDate',
      key: 'gameDate',
      ...(isMobile ? {} : { fixed: 'left' }),
      width: 100,
    },
  ];

  if (sport === Sport.nba) {
    return [...commonColumns, ...nbaColumns];
  }

  if (sport === Sport.nfl) {
    if (position === 'QB') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Passing'], ...nflColumns['Rushing']];
    }
    if (position === 'RB') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Rushing'], ...nflColumns['Receiving']];
    }
    if (position === 'TE' || position === 'WR') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Receiving'], ...nflColumns['Rushing']];
    }
    if (position === 'PK') {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Kicking']];
    }
    if (/^DE|DT|CB|LB|S$/.test(position || '')) {
      // @ts-ignore
      return [...commonColumns, ...nflColumns['Defense']];
    }
    return [...commonColumns];
  }

  if (sport === Sport.mlb) {
    if (position === 'P') {
      // @ts-ignore
      return [...commonColumns, ...mlbColumns['Pitching'], ...mlbColumns['Hitting'], ...mlbColumns['BaseRunning']];
    }

    // @ts-ignore
    return [...commonColumns, ...mlbColumns['Hitting'], ...mlbColumns['BaseRunning']];
  }

  if (sport === Sport.nhl) {
    // @ts-ignore
    if (position === 'G') return [...commonColumns, ...nhlColumns['Goalie']];

    // @ts-ignore
    return [...commonColumns, ...nhlColumns['Metric']];
  }

  return [];
};
