import { NbaStatsBody } from './prop-request-body';

export enum NflStatsMetric {
  RUSH_YDS = 'rush_yds',
  RUSH_CARRIES = 'rush_att',
  RUSH_TD = 'rush_td',
  RUSH_AVG = 'rush_yds_per_att',
  RUSH_LONG_RUSH = 'rush_long',

  PASSING_SACKED = 'pass_sacked',
  PASSING_ATTEMPTS = 'pass_att',
  PASSING_AVG = 'pass_yds_per_cmp',
  PASSING_TD = 'pass_td',
  PASSING_YDS = 'pass_yds',
  PASSING_INT = 'pass_intt',
  PASSING_COMP = 'pass_cmp',
  PASSING_LONG = 'pass_long',

  // FANTASY_STANDARD = 'fantasyPointsDefault>standard',
  // FANTASY_PPR = 'fantasyPointsDefault>PPR',
  // FANTASY_HALF_PPR = 'fantasyPointsDefault>halfPPR',

  RECEIVING_RECS = 'rec',
  RECEIVING_YDS = 'rec_yds',
  RECEIVING_AVG = 'rec_yds_per_rec',
  RECEIVING_TD = 'rec_td',
  RECEIVING_LONG_REC = 'rec_long',
  RECEIVING_TARGETS = 'targets',

  // KICKING_FG_LONG = 'Kicking>fgLong',
  KICKING_FG_MADE = 'fgm',
  KICKING_FG_ATTEMPTS = 'fga',
  KICKING_FG_PCT = 'fg_perc',
  KICKING_XP_MADE = 'xpm',
  KICKING_XP_ATTEMPTS = 'xpa',
  KICKING_PTS = 'scoring',

  DEFENSE_TOTAL_TACKLES = 'tackles_combined',
  // DEFENSE_DEF_TD = 'Defense>defTD',
  DEFENSE_SOLO_TACKLES = 'tackles_solo',
  DEFENSE_TFL = 'tackles_loss',
  DEFENSE_QB_HITS = 'qb_hits',
  DEFENSE_DEF_INTERCEPTIONS = 'def_int',
  DEFENSE_SACKS = 'sacks',
  DEFENSE_PASS_DEFLECTIONS = 'pass_defended',

  // FUMBLES_LOST = 'Defense>fumblesLost',
  FUMBLES = 'fumbles',
  FUMBLES_RECOVERED = 'fumbles_rec',
}

export type NflPlayerStats = {
  teamAbv: string; // 'WSH';
  longName: string; // 'Terry McLaurin';
  playerID: string; // '3121422';
  team: string; // 'WSH';
  teamID: string; // '32';
  gameID: string; // '20231022_WSH@NYG';
  fantasyPoints: string; // '15.0';
  Passing?: {
    qbr: string; // '65.4';
    rtg: string; // '86.1';
    sacked: string; // '1-4';
    passAttempts: string; // '39';
    passAvg: string; // '4.8';
    passTD: string; // '1';
    passYds: string; // '186';
    int: string; // '0';
    passCompletions: string; // '26';
  };
  Receiving?: {
    receptions: string; // '6';
    recTD: string; // '0';
    longRec: string; // '31';
    targets: string; // '9';
    recYds: string; // '90';
    recAvg: string; // '15.0';
  };
  Defense?: {
    fumblesLost: string; // '0';
    defensiveInterceptions: string; // '0';
    fumbles: string; // '1';
    fumblesRecovered: string; // '0';
    totalTackles: string; // '13';
    defTD: string; // '0';
    soloTackles: string; // '9';
    tfl: string; // '0';
    qbHits: string; // '0';
    sacks: string; // '0';
    passDeflections: string; // '0';
  };
  Rushing?: {
    rushAvg: string; // '6.0';
    rushYds: string; // '72';
    carries: string; // '12';
    longRush: string; // '18';
    rushTD: string; // '2';
  };
  Kicking?: {
    fgLong: string; // '27';
    fgMade: string; // '1';
    fgAttempts: string; // '2';
    xpMade: string; // '3';
    fgPct: string; // '50.0';
    kickingPts: string; // '6';
    xpAttempts: string; // '3';
  };
  fantasyPointsDefault: {
    standard: string; // '9.0';
    PPR: string; // '15.0';
    halfPPR: string; // '12.0';
  };
};

export type NflGameStat = Record<string, NflPlayerStats>;

export type NflGameStats = {
  statusCode: number;
  body: NflGameStat;
};

export type NflStatTypes = 'away' | 'home' | 'all' | 'vs';

export type NflStatType = Record<
  NflStatTypes,
  {
    percentage: number;
    stats: {
      game: string;
      gameDate: string;
      total: number;
      thresholdMet: boolean;
      metaData: NflGameLogStats[];
    }[];
  }
>;

export type NflPlayerStatResult = {
  stats: NflStatType;
  criteria: NbaStatsBody;
  rating: number | null;
  streak: number;
  hitDifferential: number;
  missDifferential: number;
  streakDifferential: number;
};

export type NflGameLogStats = {
  gs: string; // '*';
  age: string; // '28.347';
  opp: string; // 'CAR';
  opp_name_abbr: string; // 'CAR';
  date: string; // '2024-12-22';
  game_date: string; //  '2024-12-22';
  game_location: string; // '@';
  team: string; // 'ARI';
  team_name_abbr: string; // 'ARI';

  ranker: string; // '15';
  st_pct: string; // '0';
  def_int: string; // '0';
  def_pct: string; // '100';
  defense: string; // '68';
  fumbles: string; // '0';
  off_pct: string; // '0';
  offense: string; // '0';
  fumbles_rec: string; // '0';
  game_result: string; // 'L 30-36 (OT)';
  game_num: string; // '15';
  week_num: string; // '16';

  sacks: string; // '0.0';
  qb_hits: string; // '0';
  safety_md: string; // '';
  def_int_td: string; // '0';
  def_int_yds: string; // '0';
  def_int_long: string; //  '0';
  tackles_loss: string; // '0';
  tackles_solo: string; // '2';
  pass_defended: string; // '0';
  special_teams: string; // '0';
  fumbles_forced: string; //  '0';
  fumbles_rec_td: string; // '0';
  fumbles_rec_yds: string; //'0';
  tackles_assists: string; //'4';
  tackles_combined: string; //'6';

  all_td: string; // '1';
  pass_td: string; // '1';
  scoring: string; // '6';
  pass_att: string; // '32';
  pass_cmp: string; // '20';
  pass_int: string; // '1';
  pass_yds: string; // '202';
  pass_long: string; // '38';
  two_pt_md: string; // '';
  pass_rating: string; // '77.9';
  pass_sacked: string; // '3';
  fumbles_lost: string; // '1';
  pass_td_perc: string; // '3.1';
  pass_cmp_perc: string; // '62.5';
  pass_int_perc: string; // '3.1';
  pass_sacked_yds: string; // '26';
  pass_sacked_perc: string; // '8.57';
  pass_yds_per_att: string; // '6.3';
  pass_yds_per_cmp: string; // '10.1';
  pass_adj_yds_per_att: string; // '5.53';
  pass_net_yds_per_att: string; // '5.03';
  pass_adj_net_yds_per_att: string; // '4.31';

  rush_td: string; // '1';
  rush_att: string; // '8';
  rush_yds: string; // '63';
  rush_long: string; // '20';
  rush_receive_td: string; // '1';
  rush_yds_per_att: string; // '7.8';

  rec: string; // '1';
  rec_td: string; // '0';
  rec_yds: string; // '49';
  targets: string; //  '4';
  rec_long: string; // '36';
  catch_pct: string; // '100.0';
  rec_yds_per_rec: string; // '12.3';
  rec_yds_per_tgt: string; // '12.3';

  touches: string; // '19';
  yds_per_touch: string; //  '8.7';
  yds_from_scrimmage: string; // '166';

  kick_ret: string; // '1';
  punt_ret: string; // '1';
  kick_ret_td: string; //  '0';
  punt_ret_td: string; // '0';
  kick_ret_yds: string; // '26';
  punt_ret_yds: string; // '13';
  kick_ret_yds_per_ret: string; // '26.00';
  punt_ret_yds_per_ret: string; // '13.00';

  fga: string; // '';
  fgm: string; // '';
  xpa: string; // '';
  xpm: string; // '';
  fg_perc: string; // '';
  xp_perc: string; // '';
  fga1: string; //'';
  fga2: string; //'';
  fga3: string; //'1';
  fga4: string; // '1';
  fga5: string; //'1';
  fgm1: string; //'';
  fgm2: string; // '';
  fgm3: string; // '1';
  fgm4: string; // '1';
  fgm5: string; //'1';
  punt: string; // '0';
  kickoff: string; //'7';
  punt_tb: string; //'0';
  punt_yds: string; //'0';
  kickoff_tb: string; //'4';
  punt_in_20: string; //'0';
  kickoff_yds: string; //'457';
  punt_tb_pct: string; //'';
  punt_blocked: string; //'0';
  punt_net_yds: string; //'0';
  kickoff_tb_pct: string; // '57';
  punt_in_20_pct: string; //'';
  kickoff_yds_avg: string; //'65';
  punt_ret_yds_opp: string; //'0';
  punt_yds_per_punt: string; //'';
  punt_net_yds_per_punt: string; //'';
};
