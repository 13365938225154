import React from 'react';
import { Tag } from 'antd';
import cn from 'classnames';
import { StarFilled, DislikeFilled, CaretUpFilled } from '@ant-design/icons';
import './PriceRating.scss';

const isDevelopment = process.env.NODE_ENV === 'development';

export function Rating({ rating }: { rating: number }) {
  if (isDevelopment) {
    // return <>{rating && rating.toFixed(1)}</>;
  }

  /*
  
  A+ > 100
  A > 90
  B > 80
  C > 70
  D > 60
  F < 60
  */

  const tagCss = cn('price-rating-tag', {
    'price-rating-tag-100': rating > 110,
    'price-rating-tag-80': rating > 80 && rating <= 110,
    'price-rating-tag-60': rating > 60 && rating <= 80,
  });

  // const color = rating > 100 ? '#D1B464' : rating > 80 ? '#50853a' : rating > 60 ? '#0f50d4' : '#cd201f';
  const icon = rating > 90 ? <StarFilled /> : rating > 60 ? <CaretUpFilled /> : <DislikeFilled />;
  const label = rating > 110 ? 'A+' : rating >= 90 ? 'A' : rating > 80 ? 'B' : rating > 60 ? 'C' : 'F';

  return (
    <Tag className={tagCss} style={{ margin: 0 }} icon={icon}>
      {label}
    </Tag>
  );
}
