import React from 'react';
import { Flex } from 'antd';
import { UpCircleFilled, DownCircleFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import cn from 'classnames';

import './MarketTableStats.scss';

function OverUnderDisplay({
  overUnder,
  threshold,
  compact = false,
}: {
  overUnder: 'over' | 'under';
  threshold: number;
  compact?: boolean;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const marketTableStatsHeaderCss = cn('market-table-stats__header', {
    'market-table-stats__header--mobile': isTabletOrMobile,
    'market-table-stats__header--compact': compact,
    'market-table-stats__header--over': overUnder === 'over',
    'market-table-stats__header--under': overUnder === 'under',
  });

  const marketTableStatsOverUnderCss = cn('market-table-stats__overUnder', {
    'market-table-stats__overUnder--mobile': isTabletOrMobile,
    'market-table-stats__overUnder--compact': compact,
  });

  const marketTableStatsThresholdCss = cn('market-table-stats__threshold', {
    'market-table-stats__threshold--mobile': isTabletOrMobile,
    'market-table-stats__threshold--compact': compact,
  });

  return (
    <Flex align="center" gap={compact || isTabletOrMobile ? 4 : 8} className={marketTableStatsHeaderCss}>
      <div className={marketTableStatsOverUnderCss}>{overUnder}</div>
      <div>
        {overUnder === 'over' ? (
          <UpCircleFilled style={{ fontSize: 16, color: 'green' }} />
        ) : (
          <DownCircleFilled style={{ fontSize: 16, color: 'red' }} />
        )}
      </div>
      <div className={marketTableStatsThresholdCss}>{threshold}</div>
    </Flex>
  );
}

export default OverUnderDisplay;
