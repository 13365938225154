import { useState, useEffect, useMemo } from 'react';
import { Sport } from '../../../types';
import { Flex, Select, Divider } from 'antd';
import { useGetPicksFiltersQuery, useGetFilteredPicksQuery } from '../../../redux/queries/bettingApi';
import Pick from '../OurPicks/Pick';
import { groupPicksFormatter } from '../OurPicks/OurPicks';

function ByStat({ sportId }: { sportId: Sport }) {
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string }>({});
  const [sortBy, setSortBy] = useState<'rating' | 'threshold'>('rating');
  const { data: picksFilters = [] } = useGetPicksFiltersQuery({ sport: sportId });
  const { data: picks = [] } = useGetFilteredPicksQuery(
    { sport: sportId, filters: activeFilters, sortBy },
    { skip: Object.keys(activeFilters).length === 0 }
  );

  useEffect(() => {
    if (Object.keys(activeFilters).length === 0 && picksFilters.length > 0) {
      const marketFilter = picksFilters.find(filter => filter.value === 'marketName');
      setActiveFilters({ marketName: marketFilter?.options[0].value || '' });
    }
  }, [picksFilters, activeFilters]);

  const handleChange = ({ filter, value }: { filter: string; value: string }) => {
    console.log({ filter, value });

    if (value === 'all') {
      delete activeFilters[filter];
      return;
    }

    setActiveFilters(prev => ({
      ...prev,
      [filter]: value,
    }));
  };

  const groupPicks = useMemo(() => {
    return groupPicksFormatter(picks || []);
  }, [picks]);

  return (
    <div>
      <Flex justify="space-between" style={{ width: '100%', flexWrap: 'wrap' }} gap={8}>
        {picksFilters.map(filter => {
          return (
            <Select
              style={{ width: 'calc(50% - 8px)' }}
              defaultValue={filter.options[0]?.value || null}
              variant="filled"
              onChange={(value: string) => {
                handleChange({
                  filter: filter.value,
                  value,
                });
              }}
              options={filter.options.map(option => ({
                value: option.value,
                label: `${option.label} ${option.totalCount ? `(${option.totalCount})` : ''}`,
              }))}
            />
          );
        })}
        <Select
          style={{ width: 'calc(50% - 8px)' }}
          defaultValue={sortBy}
          variant="borderless"
          onChange={setSortBy}
          options={[
            { value: 'rating', label: 'sort by Rating' },
            { value: 'threshold', label: 'sort by Line' },
          ]}
        />
      </Flex>
      <Divider style={{ margin: '8px 0', padding: 0 }} />
      <Flex vertical={true} gap={8}>
        {groupPicks.map(pick => {
          return <Pick key={`bystat-${pick.id}`} pick={pick} condensed={false} />;
        })}
      </Flex>
    </div>
  );
}

export default ByStat;
