import React from 'react';
import { useGetMlbPlayerInfoQuery, useGetMlbTeamsQuery } from 'src/redux/queries/mlbApi';
import PlayerHeader from '../PlayerHeader';
import { MlbPlayer, Sport } from 'src/types';

type PlayerHeaderProps = {
  player: MlbPlayer;
  playerName: string;
  handleExpandSection: (e: React.MouseEvent) => void;
  isExpanded: boolean;
  hasPicks: boolean;
  gameDate?: string;
};

function splitName(injury = '') {
  const words: string[] = injury.replace(/-/g, ' ').split(' ');
  return words.map((word: string) => word.charAt(0).toUpperCase()).join('');
}

export default function MLBPlayerHeader({
  player,
  handleExpandSection,
  isExpanded,
  hasPicks,
  gameDate = '',
}: PlayerHeaderProps) {
  const { data: teams, isFetching: isFetchingTeams } = useGetMlbTeamsQuery();
  const { data: playerInfo, isFetching: isFetchingPlayerInfo } = useGetMlbPlayerInfoQuery(
    { playerId: player.playerID },
    { skip: !player.playerID }
  );

  const playerTeam = teams?.find(team => team.teamAbv === player?.team) || null;

  return (
    <div>
      <PlayerHeader
        sport={Sport.mlb}
        teamId={playerTeam?.teamAbv as string}
        loading={isFetchingTeams || isFetchingPlayerInfo}
        playerId={player.playerID}
        playerName={playerInfo?.body.longName as string}
        headshot={playerInfo?.body.mlbHeadshot as string}
        teamName={playerTeam?.teamName as string}
        teamCity={playerTeam?.teamCity as string}
        teamLogo={playerTeam?.mlbLogo1 as string}
        position={playerInfo?.body.pos as string}
        jerseyNum={playerInfo?.body.jerseyNum as string}
        handleExpandSection={handleExpandSection}
        isExpanded={isExpanded}
        hasPicks={hasPicks}
        gameDate={gameDate}
      />
    </div>
  );
}
