import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { useGetNflPlayerInfoQuery, useGetNflTeamsQuery, useGetNflPlayerStatsQuery } from '../../redux/queries/nflApi';
import { NbaStatTypes, NbaStatsBody, NflStatTypes, Sport } from '../../types';
import PlayerInfo from './PlayerInfo';
import PlayerStats from './PlayerStats';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import cn from 'classnames';

import './Player.scss';
import StatsTable from './StatsTable';
import NoOfGames from './NoOfGames';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

function round(num: number, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

const NflPlayer = ({ playerId, statsBody }: { playerId: string | null; statsBody: NbaStatsBody }) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  const { data: playerInfo, isFetching: playerInfoLoading } = useGetNflPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId }
  );

  const { data: playerStats, isFetching: playerStatsLoading } = useGetNflPlayerStatsQuery(
    {
      playerId: playerId as string,
      body: {
        ...statsBody,
        noOfGames: requestBody.noOfGames,
      },
    },
    { skip: !playerId }
  );

  const { data: teams, isLoading: teamsLoading } = useGetNflTeamsQuery();
  const playerTeam = teams?.find(team => team.teamAbv === playerInfo?.body.team);

  const dataKeys: NbaStatTypes[] = playerStats ? (Object.keys(playerStats?.stats) as NflStatTypes[]) : [];
  const chartData = playerStats?.stats
    ? dataKeys.map(key => {
        return {
          chartLabel: key === 'vs' ? `${key} ${requestBody.vsOpponent}` : key === 'all' ? 'Overall' : `${key}`,
          percentage: playerStats?.stats[key].percentage,
          xAxisKey: 'game',
          dataKey: 'total',
          referenceLine: playerStats.criteria.threshold,
          chartData: playerStats.stats[key].stats,
        };
      })
    : [];

  const playerCss = cn('player__container', {
    'player__container--mobile': isTabletOrMobile,
  });

  const playerAvgs: { header: string; longHeader: string; stat: number | string }[] = useMemo(() => {
    if (!playerInfo || !playerInfo.body?.stats) return [];

    const gamesPlayed = +playerInfo.body.stats.gamesPlayed;

    if (playerInfo.body.pos === 'QB') {
      return [
        {
          header: 'YDS',
          longHeader: 'Passing Yards',
          stat: round(+playerInfo.body.stats.Passing.passYds / gamesPlayed, 2),
        },
        {
          header: 'TDS',
          longHeader: 'Passing Tds',
          stat: round(+playerInfo.body.stats.Passing.passTD / gamesPlayed, 2),
        },
        {
          header: 'ATT',
          longHeader: 'Passing Attempts',
          stat: round(+playerInfo.body.stats.Passing.passAttempts / gamesPlayed, 2),
        },
        {
          header: 'COMP',
          longHeader: 'Passing Completions',
          stat: round(+playerInfo.body.stats.Passing.passCompletions / gamesPlayed, 2),
        },
        {
          header: 'INT',
          longHeader: 'Interceptions',
          stat: round(+playerInfo.body.stats.Passing.int / gamesPlayed, 2),
        },
      ];
    }

    if (playerInfo.body.pos === 'RB') {
      return [
        {
          header: 'YDS',
          longHeader: 'Rushing Yards',
          stat: round(+playerInfo.body.stats.Rushing.rushYds / gamesPlayed, 2),
        },
        {
          header: 'TDS',
          longHeader: 'Rushing Tds',
          stat: round(+playerInfo.body.stats.Rushing.rushTD / gamesPlayed, 2),
        },
        {
          header: 'ATT',
          longHeader: 'Rushing Attempts',
          stat: round(+playerInfo.body.stats.Rushing.carries / gamesPlayed, 2),
        },
      ];
    }

    if (playerInfo.body.pos === 'WR' || playerInfo.body.pos === 'TE') {
      return [
        {
          header: 'YDS',
          longHeader: 'Receiving Yards',
          stat: round(+playerInfo.body.stats.Receiving.recYds / gamesPlayed, 2),
        },
        {
          header: 'TDS',
          longHeader: 'Receiving Tds',
          stat: round(+playerInfo.body.stats.Receiving.recTD / gamesPlayed, 2),
        },
        {
          header: 'REC',
          longHeader: 'Receptions',
          stat: round(+playerInfo.body.stats.Receiving.receptions / gamesPlayed, 2),
        },
        {
          header: 'TGTS',
          longHeader: 'Targets',
          stat: round(+playerInfo.body.stats.Receiving.targets / gamesPlayed, 2),
        },
      ];
    }

    if (playerInfo.body.pos === 'PK') {
      return [
        {
          header: 'FGM',
          longHeader: 'Field Goals Made',
          stat: round(+playerInfo.body.stats.Kicking.fgMade / gamesPlayed, 2),
        },
        {
          header: 'FGA',
          longHeader: 'Field Goals Attempted',
          stat: round(+playerInfo.body.stats.Kicking.fgAttempts / gamesPlayed, 2),
        },
        {
          header: 'FG%',
          longHeader: 'Field Goal Percentage',
          stat: round((+playerInfo.body.stats.Kicking.fgMade / +playerInfo.body.stats.Kicking.fgAttempts) * 100, 2),
        },
      ];
    }

    // defense
    return [
      {
        header: 'SACKS',
        longHeader: 'Sacks',
        stat: round(+playerInfo.body.stats.Defense.sacks / gamesPlayed, 2),
      },
      {
        header: 'Tackles',
        longHeader: 'Tackles',
        stat: round(+playerInfo.body.stats.Defense.totalTackles / gamesPlayed, 2),
      },
      {
        header: 'INT',
        longHeader: 'Interceptions',
        stat: round(+playerInfo.body.stats.Defense.defensiveInterceptions / gamesPlayed, 2),
      },
    ];
  }, [playerInfo]);

  return (
    <div className={playerCss}>
      <Flex gap={16} align="flex-start" vertical={isTabletOrMobile}>
        {playerInfo && playerTeam && (
          <PlayerInfo
            playerAvgs={playerAvgs}
            isLoading={playerInfoLoading || teamsLoading}
            headShot={playerInfo?.body.espnHeadshot}
            teamLogo={playerTeam.espnLogo1}
            displayName={`${playerInfo.body.longName} (${playerInfo.body.pos})`}
          />
        )}
        <div style={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
          <PlayerStats data={chartData} isLoading={playerStatsLoading}>
            <NoOfGames />
          </PlayerStats>
          <StatsTable
            data={chartData}
            isLoading={playerStatsLoading}
            sport={Sport.nfl}
            position={playerInfo?.body.pos}
          />
        </div>
      </Flex>
    </div>
  );
};

export default NflPlayer;
