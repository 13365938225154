import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { useGetNhlPlayerInfoQuery, useGetNhlTeamsQuery, useGetNhlPlayerStatsQuery } from '../../redux/queries/nhlApi';
import { NbaStatsBody, Sport, NhlStatTypes } from '../../types';
import PlayerInfo from './PlayerInfo';
import PlayerStats from './PlayerStats';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import cn from 'classnames';

import './Player.scss';
import StatsTable from './StatsTable';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import NoOfGames from './NoOfGames';

function round(num: number, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

const NhlPlayer = ({ playerId, statsBody }: { playerId: string | null; statsBody: NbaStatsBody }) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  const { data: playerInfo, isLoading: playerInfoLoading } = useGetNhlPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId }
  );

  const { data: playerStats, isFetching: playerStatsLoading } = useGetNhlPlayerStatsQuery(
    {
      playerId: playerId as string,
      body: {
        ...statsBody,
        noOfGames: requestBody.noOfGames,
      },
    },
    { skip: !playerId }
  );

  const { data: teams, isLoading: teamsLoading } = useGetNhlTeamsQuery();
  const playerTeam = teams?.find(team => team.teamAbv === playerInfo?.body.team);

  const dataKeys: NhlStatTypes[] = playerStats ? (Object.keys(playerStats?.stats) as NhlStatTypes[]) : [];
  const chartData = playerStats?.stats
    ? dataKeys.map(key => {
        return {
          chartLabel: key === 'vs' ? `${key} ${requestBody.vsOpponent}` : key === 'all' ? 'Overall' : `${key}`,
          percentage: playerStats?.stats[key].percentage,
          xAxisKey: 'game',
          dataKey: 'total',
          referenceLine: playerStats.criteria.threshold,
          chartData: playerStats.stats[key].stats,
        };
      })
    : [];

  const playerCss = cn('player__container', {
    'player__container--mobile': isTabletOrMobile,
  });

  const playerAvgs: { header: string; longHeader: string; stat: number | string }[] = useMemo(() => {
    if (!playerInfo || !playerInfo.body?.stats) return [];

    const gamesPlayed = +playerInfo.body.stats.gamesPlayed;

    if (playerInfo.body.pos === 'G') {
      return [
        {
          header: 'GOALS',
          longHeader: 'Goals Against',
          stat: round(+playerInfo.body.stats.goalsAgainst / gamesPlayed, 2),
        },
        {
          header: 'SAVES',
          longHeader: 'Saves',
          stat: round(+playerInfo.body.stats.saves / gamesPlayed, 2),
        },
      ];
    }
    // goalie vs standard

    const timeOnIce = playerInfo.body.stats.timeOnIce.includes(':')
      ? +playerInfo.body.stats.timeOnIce.split(':')[0] + +playerInfo.body.stats.timeOnIce.split(':')[1] / 60
      : +playerInfo.body.stats.timeOnIce;

    return [
      {
        header: 'TOI',
        longHeader: 'Time on Ice',
        stat: round(timeOnIce / gamesPlayed, 2),
      },
      {
        header: 'PTS',
        longHeader: 'Points',
        stat: round((+playerInfo.body.stats.goals + +playerInfo.body.stats.assists) / gamesPlayed, 2),
      },
      {
        header: 'GOALS',
        longHeader: 'Goals',
        stat: round(+playerInfo.body.stats.goals / gamesPlayed, 2),
      },
      {
        header: 'AST',
        longHeader: 'Assists',
        stat: round(+playerInfo.body.stats.assists / gamesPlayed, 2),
      },
      {
        header: 'SHT',
        longHeader: 'Shots on Goal',
        stat: round(+playerInfo.body.stats.shots / gamesPlayed, 2),
      },
    ];
  }, [playerInfo]);

  return (
    <div className={playerCss}>
      <Flex gap={16} align="flex-start" vertical={isTabletOrMobile}>
        {playerInfo && playerTeam && (
          <PlayerInfo
            playerAvgs={playerAvgs}
            isLoading={playerInfoLoading || teamsLoading}
            headShot={playerInfo?.body.espnHeadshot}
            teamLogo={playerTeam.espnLogo1}
            displayName={`${playerInfo.body.longName} (${playerInfo.body.pos})`}
          />
        )}
        <div style={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
          <PlayerStats data={chartData} isLoading={playerStatsLoading}>
            <NoOfGames />
          </PlayerStats>
          <StatsTable
            data={chartData}
            isLoading={playerStatsLoading}
            sport={Sport.nhl}
            position={playerInfo?.body.pos}
          />
        </div>
      </Flex>
    </div>
  );
};

export default NhlPlayer;
