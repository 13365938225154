import React from 'react';
import { Button, Flex, Skeleton, Tag } from 'antd';
import { UpOutlined, DownOutlined, StarFilled } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { Sport } from '../../types';
import InjuryIndicator from './InjuryIndicator/InjuryIndicator';
import dayjs from 'dayjs';
import { useGetNbaTeamRosterInjuriesQuery } from 'src/redux/queries/nbaApi';
import './PlayerHeader.scss';

function splitName(injury = '') {
  const words: string[] = injury.replace(/-/g, ' ').split(' ');
  return words.map((word: string) => word.charAt(0).toUpperCase()).join('');
}

function PlayerHeader({
  sport,
  teamId,
  playerId,
  playerName,
  headshot,
  teamName,
  teamCity,
  teamLogo,
  position,
  jerseyNum,
  loading,
  handleExpandSection,
  isExpanded,
  gameDetails = null,
  showTeam = true,
  hasPicks = false,
  gameDate = '',
}: {
  sport: Sport;
  playerId: string;
  playerName: string;
  headshot: string;
  teamId: string;
  teamName: string;
  teamCity: string;
  teamLogo: string;
  position: string;
  jerseyNum: string;
  loading: boolean;
  handleExpandSection?: (e: React.MouseEvent) => void;
  isExpanded?: boolean;
  gameDetails?: React.ReactNode | null;
  showTeam?: boolean;
  hasPicks?: boolean;
  gameDate?: string;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const { data: nbaInjuries } = useGetNbaTeamRosterInjuriesQuery(
    { teamId, gameDate: dayjs(gameDate).format('YYYYMMDD') },
    { skip: sport !== Sport.nba }
  );

  const hasInjury = nbaInjuries?.find(player => player.playerId === playerId) || null;

  if (loading) {
    return (
      <div className="player-header__container">
        <Flex align="center" gap={16}>
          {!isTabletOrMobile && (
            <Flex justify="flex-end">
              <div className="player-header__teamLogo">
                <Skeleton.Avatar active style={{ width: 30, height: 30 }} />
              </div>
            </Flex>
          )}
          <div className="player-header__headshot">
            <Skeleton.Avatar active style={{ width: 50, height: 50 }} />
          </div>
          <div className="player-header__name-team">
            <div className="player-header__name">
              <Skeleton.Input active />
            </div>
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <div className="player-header__container">
      {hasPicks && (
        <div className="player-header__has-picks">
          <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}></Tag>
        </div>
      )}
      <Flex align="center">
        {!isTabletOrMobile && (
          <Flex justify="flex-end">
            <div className="player-header__teamLogo">
              <img src={teamLogo} alt={teamName} />
            </div>
          </Flex>
        )}
        <div className="player-header__headshot">
          <img src={headshot} alt={playerName} />
        </div>
        <div className="player-header__name-team">
          <Flex gap={8} align="center">
            {hasInjury && (
              <Tag
                style={{ margin: 0, padding: '0 2px', fontSize: 11, lineHeight: 1.01 }}
                color={hasInjury.injury === 'Out' || hasInjury.injury === 'Doubtful' ? '#cd201f' : 'warning'}
                bordered={false}
              >
                {splitName(hasInjury.injury)}
              </Tag>
            )}
            <div className="player-header__name">
              {playerName} #{jerseyNum} ({position})
            </div>
          </Flex>
          {showTeam && (
            <Flex align="center" gap={0}>
              <InjuryIndicator sport={sport} teamId={teamId} gameDate={gameDate} />
              <div className="player-header__team">
                {teamName} <span className="city">{teamCity}</span>
              </div>
            </Flex>
          )}
          {gameDetails && <>{gameDetails}</>}
        </div>

        <Flex
          gap={8}
          justify="flex-end"
          align="center"
          className="player-header__button"
          onClick={handleExpandSection && handleExpandSection}
        >
          {handleExpandSection && (
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              onClick={handleExpandSection}
            />
          )}
        </Flex>
      </Flex>
    </div>
  );
}

export default PlayerHeader;
