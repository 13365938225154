import { baseApi } from './baseApi';
import { OddsSport, OddsEvent, OddsMarkets, Sport, OddsSportsMarket, PicksBookmaker, PickFilter } from '../../types';
import { OddsFreePicks } from '../../types/odds-api';

export const bettingApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getFreePicks: build.query<OddsFreePicks, void>({
      query: () => `/freepicks`,
    }),
    getOddsSports: build.query<OddsSport[], void>({
      query: () => `/odds/sports`,
    }),
    getOddsSportEvents: build.query<OddsEvent[], { sportsKey: string }>({
      query: ({ sportsKey }) => `/odds/sports/${sportsKey}/events`,
    }),
    getSportEventOdds: build.query<OddsMarkets, { sportsKey: string; eventId: string }>({
      query: ({ sportsKey, eventId }) => `/odds/sports/${sportsKey}/events/${eventId}/odds`,
    }),
    getSportsMarkets: build.query<OddsSportsMarket[], { sport: Sport }>({
      query: ({ sport }) => `/odds/sports/${sport}/markets`,
    }),
    getPicksBookmakers: build.query<PicksBookmaker[], { sport: Sport }>({
      query: ({ sport }) => `/odds/sports/${sport}/picks/bookmakers`,
    }),
    getOurPicks: build.query<any[], { sport: Sport; bookmakers?: string[]; noOfPicks: number }>({
      query: ({ sport, bookmakers, noOfPicks }) => {
        return {
          url: `/odds/sports/${sport}/picks`,
          method: 'POST',
          body: {
            bookmakers: bookmakers ? bookmakers : [],
            noOfPicks,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),
    getEventPicks: build.query<any[], { eventId: string }>({
      query: ({ eventId }) => `/odds/events/${eventId}/picks`,
      keepUnusedDataFor: 0,
    }),
    getPicksFilters: build.query<PickFilter[], { sport: Sport }>({
      query: ({ sport }) => `/odds/sports/${sport}/picks/filters`,
      keepUnusedDataFor: 0,
    }),
    getFilteredPicks: build.query<
      any[],
      { sport: Sport; filters: { [key: string]: string }; sortBy: 'rating' | 'threshold' }
    >({
      query: ({ sport, filters, sortBy }) => {
        return {
          url: `/odds/sports/${sport}/picks/query`,
          method: 'POST',
          body: {
            filters,
            sortBy,
          },
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetSportsMarketsQuery,
  useGetOddsSportsQuery,
  useLazyGetOddsSportEventsQuery,
  useGetOddsSportEventsQuery,
  useGetSportEventOddsQuery,
  useGetOurPicksQuery,
  useLazyGetOurPicksQuery,
  useGetPicksBookmakersQuery,
  useGetEventPicksQuery,
  useGetFreePicksQuery,
  useGetPicksFiltersQuery,
  useGetFilteredPicksQuery,
} = bettingApi;
