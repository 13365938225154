import React from 'react';
import dayjs from 'dayjs';
import { Sport } from '../../../types';
import { useGetNbaTeamRosterInjuriesQuery } from 'src/redux/queries/nbaApi';
import { Button, Popover, Tag } from 'antd';
import { MedicineBoxFilled } from '@ant-design/icons';

import './InjuryIndicator.scss';

function InjuryIndicator({ sport, teamId, gameDate }: { sport: Sport; teamId: string; gameDate: string }) {
  const { data: nbaInjuries } = useGetNbaTeamRosterInjuriesQuery(
    { teamId, gameDate: dayjs(gameDate).format('YYYYMMDD') },
    { skip: sport !== Sport.nba }
  );

  const injuries = sport === Sport.nba ? nbaInjuries : [];

  if (!injuries || injuries.length === 0) return null;

  const injuryContent = (
    <div>
      <ul className="injury-indicator__list">
        {(injuries ?? []).map(player => (
          <li key={player.id}>
            <Tag bordered={false} color={player.injury.toLowerCase() === 'out' ? 'red' : 'warning'}>
              {player.injury}
            </Tag>
            {player.displayName} ({player.pos})
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div>
      <div>
        <Popover content={injuryContent} title={`${teamId.toUpperCase()} Injuries`} trigger="click">
          <Button
            type="text"
            variant="filled"
            icon={<MedicineBoxFilled style={{ color: '#ff7b00' }} />}
            size="small"
            shape="circle"
            style={{ padding: 0, backgroundColor: 'transparent' }}
          />
        </Popover>
      </div>
    </div>
  );
}

export default InjuryIndicator;
